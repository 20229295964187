<template>
  <base-section id="core-values">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <base-section-heading color="grey lighten-2" title="Karir" />
      <v-container class="text-justify">
        <p>
          <v-img :src="require('@/assets/working-is-fun.png')" max-width="100%" />
        </p>
        <p>
          <v-img :src="require('@/assets/become-starformer.jpg')" max-width="100%" />
        </p>
      </v-container>
    </div>
  </base-section>
</template>
<script>
export default {
  name: "SectionCoreValues",
};
</script>
